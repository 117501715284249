// API url
const defaultConfig = {
    name: "test",
    production: false,
    apiBaseUrl: "/api/"
};

/* eslint-disable */

let __aflac_sitecore_env = window.__aflac_sitecore_env || {};

let environment = {
    ...defaultConfig,
    ...__aflac_sitecore_env
};

export default environment;
