// import "../styles/main.scss";
import Vue from "vue";
import { getUser } from "../common/util";

const elementId = "mySidenav";
const vueElement = {
    el: `#${elementId}`,
    data: () => {
        return {
            message: "this is Vue left nav",
            loggedInUserDetail: {}
        };
    },
    methods: {
        openNav() {
            var x = document.getElementById("mySidenav");
            var y = document.getElementById("a121");
            var z = document.getElementById("b121");
            if (x.style.display === "block") {
                x.style.display = "none";
                z.style.display = "none";
                y.style.display = "block";
            } else {
                x.style.display = "block";
                z.style.display = "block";
                y.style.display = "none";
            }
        }
    },
    mounted() {
        this.loggedInUserDetail = {};
        getUser().then(resData => {
            this.loggedInUserDetail = resData;
            this.loggedInUserDetail.Image = resData.Image + "?" + new Date().getTime();
        });
    }
};

if (document.getElementById(elementId)) {
    new Vue(vueElement);
}
