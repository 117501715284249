import Vue from "vue";

const FAQElementId = "faq";
export const faq = {
    el: `#${FAQElementId}`,
    data: () => {
        return {
            message: "this i from FAQ js"
        };
    }
};

if (document.getElementById(FAQElementId)) {
    new Vue(faq).$mount();
}
