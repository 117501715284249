import Vue from "vue";
import moment from "vue-moment";
import $ from "jquery";
import httpClient from "../services/http-client";
import environment from "../services/environment";
import { getUser } from "../common/util";

Vue.use(moment);

const dashboardElementId = "dashboard";
export const vueElement = {
    el: `#${dashboardElementId}`,

    data: () => {
        return {
            errorInDeletetion: false,
            deleteStep1: true,
            siteDeleted: false,
            showSiteDelModal: false,
            sitesDeleteSuccessMsg: false,
            isEnableQrCode: false,
            accountLists: [],
            siteName: "",
            total_Record: "",
            resMsg: "",
            fname: "",
            loggedInUserDetail: {},
            enrollmentDomain: environment.enrollmentDomain
        };
    },
    methods: {
        confirmDelete(name, id, event) {
            this.deleteStep1 = true;
            this.sitesDeleteSuccessMsg = false;
            if (event) {
                this.siteName = name;
                this.siteDeleteId = id;
                this.showSiteDelModal = true;
            }
        },
        deleteSite(id) {
            //this.showSiteDelModal = false;
            this.siteDeleted = false;
            this.deleteStep1 = false;
            let data = {
                ItemId: "{" + id + "}"
            };

            /*setTimeout(()=>{
                console.log("aaa:", this.accountLists, id);
                this.accountLists = this.accountLists.filter(item => item.MicrositeId !== id);
                this.errorInDeletetion = false;
                //this.siteDeleted = true;
                this.errorInDeletetion = true;
            },2000)*/
            httpClient
                .post("Delete/DeleteStatus", data)
                .then(result => {
                    if (!result.data.ItemId) {
                        this.siteDeleted = false;
                        this.errorInDeletetion = true;
                        return false;
                    }
                    this.siteDeleted = true;
                    this.accountLists = this.accountLists.filter(item => item.MicrositeId !== id);
                })
                .catch(error => {
                    this.errorInDeletetion = true;
                    this.siteDeleted = false;
                    //this.errorMessage = error;
                    //console.error('There was an error!', error);
                });
        },
        cancelDelete() {
            this.showSiteDelModal = false;
            return false;
        },
        closeDelete() {
            this.showSiteDelModal = false;
            this.deleteStep1 = true;
            return true;
        },
        viewQRCode(base64) {
            const title = "QR Code";
            //const data = "data:application/pdf;base64," + base64;/
            let base64EncodedPDF = base64; // the encoded string
            let decodedPdfContent = atob(base64EncodedPDF);
            let byteArray = new Uint8Array(decodedPdfContent.length);
            for (let i = 0; i < decodedPdfContent.length; i++) {
                byteArray[i] = decodedPdfContent.charCodeAt(i);
            }
            let blob = new Blob([byteArray.buffer], { type: "application/pdf" });
            let _pdfurl = URL.createObjectURL(blob);
            var prntWin = window.open("about:blank");
            prntWin.document.write(
                "<html><head><title>" +
                    title +
                    "</title></head><body>" +
                    '<embed width="100%" height="100%" name="plugin" src="' +
                    _pdfurl +
                    '" ' +
                    'type="application/pdf" internalinstanceid="21"></body></html>'
            );
            prntWin.document.close();
        },
        qrCodeUrl(id) {
            let url = `QRCode/Get?micrositeId=${id}`;
            httpClient.get(url).then(result => {
                this.viewQRCode(result.data.Result);
            });
        },
        copyUrl(str, id) {
            const el = document.createElement("textarea");
            el.value = str;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
            document.getElementById(id).setAttribute("title", "URL copied to clipboard");
            $("#" + id).tooltip("show");
            setTimeout(function() {
                $("#" + id).tooltip("dispose");
            }, 2000);
        },
        copyUrlMobile(str, id) {
            const el = document.createElement("textarea");
            var tootipId = document.getElementById("ttm-" + id);
            el.value = str;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
            tootipId.style.display = "inline-block";
            setTimeout(function() {
                tootipId.style.display = "none";
            }, 2000);
        },
        openAcc(id) {
            var panelId = "acc-" + id;
            var btnID = "btn-" + id;
            var panel = document.getElementById(panelId);
            let iconId = document.getElementById("img-" + id);
            let minusIconId = document.getElementById("img2-" + id);
            minusIconId.style.display = "none";
            if (panel.style.display === "block") {
                iconId.style.display = "block";
                minusIconId.style.display = "none";
                panel.style.display = "none";
                document.getElementById(btnID).classList.remove("active");
            } else {
                iconId.style.display = "none";
                minusIconId.style.display = "block";
                panel.style.display = "block";
                document.getElementById(btnID).classList.add("active");
            }
        },
        sortedData() {
            let downIconId = document.getElementById("darrow1");
            let upIconId = document.getElementById("uarrow1");

            document.getElementById("darrow2").style.display = "none";
            document.getElementById("darrow3").style.display = "none";
            document.getElementById("darrow4").style.display = "none";
            document.getElementById("darrow5").style.display = "none";

            document.getElementById("uarrow2").style.display = "none";
            document.getElementById("uarrow3").style.display = "none";
            document.getElementById("uarrow4").style.display = "none";
            document.getElementById("uarrow5").style.display = "none";
            if (upIconId.style.display === "inline-block") {
                upIconId.style.display = "none";
                downIconId.style.display = "inline-block";
                this.accountLists.sort((a, b) => a.MicrositeName.localeCompare(b.MicrositeName));
            } else {
                upIconId.style.display = "inline-block";
                downIconId.style.display = "none";
                this.accountLists.sort((a, b) => b.MicrositeName.localeCompare(a.MicrositeName));
            }
        },
        sortCreatedDate() {
            let downIconId = document.getElementById("darrow2");
            let upIconId = document.getElementById("uarrow2");

            document.getElementById("darrow1").style.display = "none";
            document.getElementById("darrow3").style.display = "none";
            document.getElementById("darrow4").style.display = "none";
            document.getElementById("darrow5").style.display = "none";

            document.getElementById("uarrow1").style.display = "none";
            document.getElementById("uarrow3").style.display = "none";
            document.getElementById("uarrow4").style.display = "none";
            document.getElementById("uarrow5").style.display = "none";
            if (upIconId.style.display === "inline-block") {
                upIconId.style.display = "none";
                downIconId.style.display = "inline-block";
                this.accountLists.sort((a, b) => {
                    var c = new Date(a.CreatedDate);
                    var d = new Date(b.CreatedDate);
                    return c - d;
                });
            } else {
                upIconId.style.display = "inline-block";
                downIconId.style.display = "none";
                this.accountLists.sort((a, b) => {
                    var c = new Date(b.CreatedDate);
                    var d = new Date(a.CreatedDate);
                    return c - d;
                });
            }
        },
        sortSiteLive() {
            let downIconId = document.getElementById("darrow5");
            let upIconId = document.getElementById("uarrow5");
            document.getElementById("darrow2").style.display = "none";
            document.getElementById("darrow1").style.display = "none";
            document.getElementById("darrow4").style.display = "none";
            document.getElementById("darrow3").style.display = "none";

            document.getElementById("uarrow2").style.display = "none";
            document.getElementById("uarrow1").style.display = "none";
            document.getElementById("uarrow4").style.display = "none";
            document.getElementById("uarrow3").style.display = "none";
            if (upIconId.style.display === "inline-block") {
                upIconId.style.display = "none";
                downIconId.style.display = "inline-block";
                this.accountLists.sort((a, b) => {
                    var c = new Date(a.LiveDate);
                    var d = new Date(b.LiveDate);
                    return c - d;
                });
            } else {
                upIconId.style.display = "inline-block";
                downIconId.style.display = "none";
                this.accountLists.sort((a, b) => {
                    var c = new Date(b.LiveDate);
                    var d = new Date(a.LiveDate);
                    return c - d;
                });
            }
        },
        sortLiveDate() {
            let downIconId = document.getElementById("darrow3");
            let upIconId = document.getElementById("uarrow3");
            document.getElementById("darrow2").style.display = "none";
            document.getElementById("darrow1").style.display = "none";
            document.getElementById("darrow4").style.display = "none";
            document.getElementById("darrow5").style.display = "none";

            document.getElementById("uarrow2").style.display = "none";
            document.getElementById("uarrow1").style.display = "none";
            document.getElementById("uarrow4").style.display = "none";
            document.getElementById("uarrow5").style.display = "none";
            if (upIconId.style.display === "inline-block") {
                upIconId.style.display = "none";
                downIconId.style.display = "inline-block";
                this.accountLists.sort((a, b) => {
                    var c = new Date(a.LiveDate);
                    var d = new Date(b.LiveDate);
                    return c - d;
                });
            } else {
                upIconId.style.display = "inline-block";
                downIconId.style.display = "none";
                this.accountLists.sort((a, b) => {
                    var c = new Date(b.LiveDate);
                    var d = new Date(a.LiveDate);
                    return c - d;
                });
            }
        },
        sortVisits() {
            let downIconId = document.getElementById("darrow4");
            let upIconId = document.getElementById("uarrow4");
            document.getElementById("darrow2").style.display = "none";
            document.getElementById("darrow3").style.display = "none";
            document.getElementById("darrow1").style.display = "none";
            document.getElementById("darrow5").style.display = "none";

            document.getElementById("uarrow2").style.display = "none";
            document.getElementById("uarrow3").style.display = "none";
            document.getElementById("uarrow1").style.display = "none";
            document.getElementById("uarrow5").style.display = "none";
            if (upIconId.style.display === "inline-block") {
                upIconId.style.display = "none";
                downIconId.style.display = "inline-block";
                this.accountLists.sort((a, b) => parseInt(a.Visits, 10) - parseInt(b.Visits, 10));
            } else {
                upIconId.style.display = "inline-block";
                downIconId.style.display = "none";
                this.accountLists.sort((a, b) => parseInt(b.Visits, 10) - parseInt(a.Visits, 10));
            }
        },
        async onMounted() {
            // this.loggedInUserDetail = JSON.parse(localStorage.getItem("loggedInUserDetail"));
            this.loggedInUserDetail = await getUser();

            // let username = getQueryStringValue("username");
            var obj = {
                UserName: this.loggedInUserDetail.EmailAddress,
                SortOrder: "asc",
                SortColumn: "account"
            };
            httpClient.post("Microsite/GetMicrositeList", obj).then(result => {
                //httpClient.get("Microsite/GetMicrositeList").then(result => {
                this.total_Record = result.data.TotalRecord;
                this.resMsg = result.data.TotalRecord;
                this.accountLists = result.data.MicroSiteList;
                for (let i = 0; i < this.accountLists.length; i++) {
                    this.accountLists[i].MicrositeId = this.accountLists[i].MicrositeId.replace("{", "").replace(
                        "}",
                        ""
                    );
                }
                setTimeout(() => {
                    let qrCodeFlyer = document.body.contains(document.getElementById("qrcodeflyer"));
                    if (qrCodeFlyer) {
                        this.isEnableQrCode = true;
                    } else {
                        this.isEnableQrCode = false;
                    }
                }, 100);
            });

            if (this.loggedInUserDetail) {
                this.fname = this.loggedInUserDetail.FirstName;
            } else {
                this.fname = "John"; // for testing perpose
            }
        },

        goToPreviewPage(url) {
            let returnURL = "/Account";
            var encodedString = encodeURIComponent(returnURL);
            document.cookie = "previewReturnURL=" + encodedString;
            if (environment.name == "local") {
                window.location.href = "/employeePreview.html";

                // window.open("/employeePreview.html", "_blank");
            } else {
                window.location.href = url;

                // window.open(url, "_blank");
            }
        }
    },
    mounted() {
        this.onMounted();
    },
    watch: {
        deleteStatusUpdate(newVal, oldVal) {
            if (!this.deleteStep1 && !newVal) {
                return true;
            } else {
                return false;
            }
        }
    },
    computed: {
        deleteStatusUpdate() {
            return this.deleteStep1;
        }
    }
};

if (document.getElementById(dashboardElementId)) {
    new Vue(vueElement);
}
