import Vue from "vue";
import Vuelidate from "vuelidate";
import "bootstrap";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import { BootstrapVue } from "bootstrap-vue";

Vue.use(BootstrapVue);
Vue.use(Vuelidate);

import "../scss/main.scss";

import "./test-profile";
import "./accounts";
import "./profile";
import "./header";
import "./preview";
import "./leftnav";
import "./site-management";
import "./dashboard";
import "./accessDenied";
import "./employeePreview";
import "./agentSearch";
import "./404NotFound";
import "./enrollmentExpire";
import "./faq";
import "./report";
import "./suggestive-selling";

Vue.use(BootstrapVue);
