// import "../styles/main.scss";
import Vue from "vue";

const elementId = "header";
export const vueElement = {
    el: `#${elementId}`,
    data: () => {
        return {
            message: "this is Vue left nav"
        };
    },
    methods: {
        openNav() {
            var x = document.getElementById("mySidenav");
            var y = document.getElementById("a121");
            var z = document.getElementById("b121");

            if (x.style.display === "block") {
                x.style.display = "none";
                z.style.display = "none";
                y.style.display = "block";

                document.getElementById("overlay-backdrop").style.display = "none";
            } else {
                x.style.display = "block";
                z.style.display = "block";
                y.style.display = "none";

                document.getElementById("overlay-backdrop").style.display = "block";
            }
        }
    }
};

if (document.getElementById(elementId)) {
    new Vue(vueElement).$mount();
}
