import Vue from "vue";

const agentSearchId = "404NotFound";
const testProfile = {
    el: `#${agentSearchId}`,
    data: () => {
        return {
            message: "this i from Agent Search js"
        };
    }
};

if (document.getElementById(agentSearchId)) {
    new Vue(testProfile).$mount();
}
