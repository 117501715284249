export function numberValidation(value) {
    if (value && removeNumberFormatting(value).length !== 10) {
        return false;
    }
    return true;
}

export function numberFormatting(value) {
    let x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    return !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
}

export function removeNumberFormatting(value) {
    if (value) {
        return value.replace(/[^a-zA-Z0-9]/g, "");
    }
    return [];
}
