import Vue from "vue";
import Vuelidate from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
Vue.use(Vuelidate);

const elementId = "test-profile";
const vueElement = {
    el: `#${elementId}`,
    data: {
        user: {
            firstName: "",
            lastName: "",
            email: "",
            Scheduleurl: "",
            phone: "",
            image: null
        },
        submitted: false
    },
    validations: {
        user: {
            firstName: { required },
            lastName: { required },
            email: { required, email },
            phone: { required }
        }
    },
    methods: {
        submitProfile() {
            this.submitted = true;

            // stop here if form is invalid
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            alert("SUCCESS!! :-)\n\n" + JSON.stringify(this.user));
        }
    }
};

if (document.getElementById(elementId)) {
    new Vue(vueElement).$mount();
}
