import axios from "axios";

import environment from "./environment";
import cm_cd_api_mapping from "./cm_cd_api_mapping";

let config = {
    // baseURL: environment.apiBaseUrl,
    headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache"
        // anything you want to add to the headers
    }
};

const httpClient = axios.create(config);

function isCMRequest(apiName) {
    return cm_cd_api_mapping.cm.indexOf(apiName) > -1 ? true : false;
}
//add interceptor here
const getAuthToken = () => localStorage.getItem("token");

const authInterceptor = config => {
    let apiName = config.url;
    if (config.method == "get") {
        apiName = config.url.split("?")[0];
    }

    config.baseURL = isCMRequest(apiName)
        ? environment.cmUrl + environment.apiBaseUrl
        : environment.cdUrl + environment.apiBaseUrl;
    config.headers["Authorization"] = getAuthToken();
    config.headers["Access-Control-Allow-Origin"] = "*";
    return config;
};

httpClient.interceptors.request.use(authInterceptor);

// interceptor to catch errors
const errorInterceptor = error => {
    // all the error responses
    switch (error.response.status) {
        case 400:
            console.error(error.response.status, error.message);
            //  notify.warn('Nothing to display','Data Not Found');
            break;

        case 401: // authentication error, logout the user
            //  notify.warn( 'Please login again', 'Session Expired');
            localStorage.removeItem("token");
            //  router.push('/auth');
            break;

        default:
            console.error(error.response.status, error.message);
        // notify.error('Server Error');
    }
    return Promise.reject(error);
};

// Interceptor for responses
const responseInterceptor = response => {
    switch (response.status) {
        case 200:
            // yay!
            break;
        // any other cases
        default:
        // default case
    }

    return response;
};

httpClient.interceptors.response.use(responseInterceptor, errorInterceptor);

export default httpClient;
