import Vue from "vue";

const REPORTElementId = "report";
export const report = {
    el: `#${REPORTElementId}`,
    data: () => {
        return {
            message: "this i from REPORT js"
        };
    }
};

if (document.getElementById(REPORTElementId)) {
    new Vue(report).$mount();
}
