import Vue from "vue";
import Vuelidate from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { helpers } from "vuelidate/lib/validators";
import VueMask from "v-mask";

import httpClient from "../services/http-client";
import environment from "../services/environment";

import { getQueryStringValue, getUser, getCookie } from "../common/util";

Vue.use(Vuelidate);
Vue.use(VueMask);

const phoneNumberValidator = helpers.regex(
    "phoneNumberValidator",
    /^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/gi
);
//const phoneNumberValidator = helpers.regex("phoneNumberValidator", /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/);
// const emailDomainValidator = helpers.regex("emailDomainValidator", /^[A-Za-z0-9._%+-]+@us.aflac.com$/);
let loggedInUserDetail;
const emailDomainValidator = (value, vm) => {
    // let loggedInUserDetail = await getUser();
    if (!value) {
        return false;
    }
    if (loggedInUserDetail.AllowedDomain) {
        let allowedEmailDomains = loggedInUserDetail.AllowedDomain;
        allowedEmailDomains = allowedEmailDomains.split("|");
        if (Array.isArray(allowedEmailDomains) && allowedEmailDomains.length > 0) {
            for (let i = 0; i < allowedEmailDomains.length; i++) {
                let domain = value.slice(value.lastIndexOf("@"));
                if (domain.toLowerCase().trim() === allowedEmailDomains[i].toLowerCase().trim()) {
                    return true;
                }
            }
            return false;
        }
    }
    return true;
};

let invalidPhoneNumbersList = [];
const phoneNumberValidatorDynamic = (value, vm) => {
    const noSpecialCharactersValue = value.replace(/[^a-zA-Z0-9]/g, "");
    if (!noSpecialCharactersValue || noSpecialCharactersValue.charAt(0) == "0") {
        return false;
    }
    if (invalidPhoneNumbersList) {
        if (invalidPhoneNumbersList.includes(noSpecialCharactersValue)) {
            return false;
        } else {
            return true;
        }
    }
};

const elementId = "profile";

export const vueElement = {
    el: `#${elementId}`,
    data() {
        return {
            user: {
                firstName: "",
                lastName: "",
                email: "",
                Scheduleurl: "",
                phone: "",
                image: null
            },
            submitted: false,
            fname: "",
            imageName: "",
            initValue: null,
            profileUpdated: false,
            userEmail: "",
            imgExtension: "",
            counter: 0,
            selectedEmail: "",
            showEmailLink: false,
            emailItemRef: null,
            dirtyFlagRef: null,
            checkDirty: "pristine",
            cookieEmail: "",
            isProfilePhotoPreviewErr: true,
            isProfilePhotoInvalid: true,
            profilePhotoName: "",
            displayProfilePhotoSection: false,
            profilePhoto: "",
            profilePhotoLevels: ["Upload Profile Photo", "Retry", "Change Profile Photo"],
            profilePhotoLevelDisplay: "",
            profilePhotoExtensionArr: [],
            profilePhotoExtension: "",
            profilePhotoSize: "500",
            invalidPhoneNumbers: []
        };
    },
    watch: {
        user: {
            handler() {
                if (this.isFormDirty() || this.isFormEmpty()) {
                    this.dirty = true;
                    this.checkDirty = "dirty";
                } else {
                    this.dirty = false;
                    this.checkDirty = "pristine";
                }
            },
            deep: true
        },
        checkDirty: function(newVal) {
            this.onDirtyChanged(newVal);
        }
    },
    validations: {
        user: {
            firstName: { required },
            lastName: { required },
            email: { required, email, emailDomainValidator },
            phone: { required, phoneNumberValidator, phoneNumberValidatorDynamic },
            //image: {}
            image: {
                required: function() {
                    if (this.imageName && this.isProfilePhotoPreviewErr) {
                        return false;
                    } else {
                        return true;
                    }
                }
            }
        }
    },
    methods: {
        isFormDirty: function() {
            for (let prop in this.user) {
                if (this.user[prop] !== this.initValue[prop]) {
                    return true;
                }
            }
            return false;
        },
        isFormEmpty: function() {
            for (let prop in this.user) {
                if (!this.user[prop] && prop != "Scheduleurl" && prop != "image") {
                    return true;
                }
            }
            return false;
        },
        getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
        handleFileUpload(e) {
            this.imageName = e.target.files[0].name;
            let uploadedFile = e.target.files[0];
            //this.imageExtension = "." + this.imageName.split(".")[1];
            this.imageExtension = "." + this.imageName.split(".").pop();
            const allowedProfileExtensionArr = this.profilePhotoExtension.split(",");
            const fileExtension = this.imageName.split(".").pop();
            const profilePhotoSizeSizeLimit = this.profilePhotoSize;
            if (!allowedProfileExtensionArr.includes(fileExtension.toLowerCase())) {
                this.displayProfilePhotoSection = true;
                this.isProfilePhotoPreviewErr = true;
                this.isProfilePhotoInvalid = true;
                this.profilePhotoLevelDisplay = this.profilePhotoLevels[1];
                return false;
            } else if (Math.ceil(uploadedFile.size / 1024) > profilePhotoSizeSizeLimit) {
                this.displayProfilePhotoSection = true;
                this.isProfilePhotoPreviewErr = true;
                this.isProfilePhotoInvalid = false;
                this.profilePhotoLevelDisplay = this.profilePhotoLevels[1];
                return;
            } else {
                this.getBase64(e.target.files[0]).then(data => {
                    this.profilePhotoLevelDisplay = this.profilePhotoLevels[0];
                    //this.clientLogoImage = data;
                    this.user.image = data;
                    this.displayProfilePhotoSection = true;
                    this.isProfilePhotoPreviewErr = false;
                    this.profilePhotoLevelDisplay = this.profilePhotoLevels[2];
                    return true;
                });
            }
        },
        submitProfile() {
            this.submitted = true;
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.cookieEmail = getCookie("username");
            var data = {
                FirstName: this.user.firstName,
                LastName: this.user.lastName,
                EmailAddress: this.cookieEmail,
                SchedulingLink: this.user.Scheduleurl,
                PhoneNumber: this.user.phone,
                Image: this.user.image,
                ImgExtension: this.imageExtension,
                AliasEmail: this.user.email
            };

            /* eslint-disable */
            if (data.Image) {
                let imgStripIndex = data.Image.indexOf(",") + 1;
                data.Image = data.Image.substring(imgStripIndex);
            }

            httpClient.post("account/Save", data).then(result => {
                // this.profileUpdated = true;
                loggedInUserDetail.FirstName = data.FirstName;
                loggedInUserDetail.LastName = data.LastName;
                loggedInUserDetail.SchedulingLink = data.SchedulingLink;
                loggedInUserDetail.PhoneNumber = data.PhoneNumber;
                loggedInUserDetail.AliasEmail = data.AliasEmail;
                localStorage.setItem("loggedInUserDetail", JSON.stringify(loggedInUserDetail));
                this.checkDirty = "navigate";
                if (environment.name == "local") {
                    window.location.href = "/dashboard.html";
                } else {
                    window.location.href = result.data.RedirectUrl;
                }
            });
        },
        emailAutoList() {
            const popularEmail = "us.aflac.com";
            this.showEmailLink = false;
            if (this.emailItemRef.value.match("@")) {
                var afterAt = this.emailItemRef.value.substring(
                    this.emailItemRef.value.indexOf("@") + 1,
                    this.emailItemRef.value.length
                );
                if (afterAt == "") {
                    this.showEmailLink = true;
                    this.selectedEmail = this.emailItemRef.value + popularEmail;
                }
            }
        },
        onItemSelected() {
            this.user.email = this.selectedEmail;
            this.showEmailLink = false;
        },
        onDirtyChanged(val) {
            this.checkDirty = val;
        },
        onMessageClosed() {
            //alert(this.checkDirty);
            this.checkDirty = "dirty";
            //this.checkDirty = "navigate";
            var link = document.getElementById("dirtyFlag").getAttribute("data-buttonVal");
            //window.location.href = link;
        },
        onMounted() {
            // Profile image upload display levels
            let profileImageDisplayLabelId = document.getElementById("ProfileImageDisplayLabelId");
            if (profileImageDisplayLabelId != null) {
                this.profilePhotoLevels = [];
                this.profilePhotoLevels = profileImageDisplayLabelId.value.split(",").map(String);
            }

            // invalid phone numbers from hidden property
            let invalidPhoneNumbersId = document.getElementById("InvalidPhoneNumbersId");
            if (invalidPhoneNumbersId != null) {
                //this.invalidPhoneNumbers = invalidPhoneNumbersId.value.split(",").map(String);
                invalidPhoneNumbersList = invalidPhoneNumbersId.value.split(",").map(String);
            }

            this.profilePhotoLevelDisplay = this.profilePhotoLevels[0];
            let ProfilePhotoExtensionId = document.getElementById("ProfilePhotoExtensionId");
            let ProfilePhotoSizeId = document.getElementById("ProfilePhotoSizeId");
            let ProfilePhotoSection = document.getElementById("ProfilePhotoSection");

            if (typeof ProfilePhotoSection != "undefined") {
                this.profilePhotoExtension = ProfilePhotoExtensionId.value;
                this.profilePhotoSize = ProfilePhotoSizeId.value;
            }

            this.emailItemRef = this.$refs["email"];
            this.dirtyFlagRef = this.$refs["dirtyFlag"];

            if (this.counter > 0) {
                return;
            }
            // this.userEmail = getQueryStringValue("username");
            // if (this.userEmail) {
            this.initValue = { ...this.user };
            getUser().then(resData => {
                // let resData = environment.name === "local" ? result.data[0] : result.data;
                if (resData) {
                    loggedInUserDetail = resData;
                    this.checkDirty = "pristine";
                    this.user.firstName = resData.FirstName;
                    this.user.lastName = resData.LastName;
                    //this.user.email = resData.EmailAddress;
                    this.user.email = resData.AliasEmail;
                    this.user.Scheduleurl = resData.SchedulingLink;
                    this.user.phone = resData.PhoneNumber;
                    this.fname = resData.FirstName;
                    //this.user.image = resData.Image;

                    if (resData.FirstName) {
                        this.profilePhotoLevelDisplay = this.profilePhotoLevels[2];
                        this.displayProfilePhotoSection = true;
                        this.isProfilePhotoPreviewErr = false;
                        this.user.image = resData.Image + "?" + new Date().getTime();
                    }

                    // considering this page as first page of application and setting loggedin user detail in session for testing purpose only.
                    // we have to remove this later on.
                    // localStorage.setItem("loggedInUserDetail", JSON.stringify(resData));
                    this.initValue = { ...this.user };
                }
                this.checkDirty = "pristine";
            });
            // }
        }
    },
    mounted() {
        this.onMounted();
    }
};

if (document.getElementById(elementId)) {
    new Vue(vueElement);
}
