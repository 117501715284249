import Vue from "vue";

const accountsElementId = "accounts";
const testProfile = {
    el: `#${accountsElementId}`,
    data: () => {
        return {
            message: "this i from account js"
        };
    }
};

if (document.getElementById(accountsElementId)) {
    new Vue(testProfile).$mount();
}
