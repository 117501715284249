import Vue from "vue";
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);
import $ from "jquery";
import vSelect from "vue-select";
Vue.component("v-select", vSelect);

const elementId = "enrollmentExpire";

const vueElement = {
    el: `#${elementId}`,
    data() {
        return {
            languageValues: "",
            options: [],
            currentItem: "English"
        };
    },
    methods: {
        selectLang(newVal) {
            let urlArr = window.location.toString().split("/");
            //let urlArr = "https://google.com/Microsite?id=781d60cd-00b0-42ae-9fb9-c31d3138b03f".split("/")
            if (Array.isArray(urlArr)) {
                let language = newVal.id;
                let remainingURL = "";
                for (var i = 4; i < urlArr.length; i++) {
                    remainingURL += urlArr[i] + "/";
                }
                remainingURL = remainingURL.substring(0, remainingURL.length - 1);
                let url =
                    urlArr.indexOf("en") > 0 || urlArr.indexOf("es-es") > 0
                        ? urlArr[0] + "//" + urlArr[2] + "/" + language + "/" + remainingURL
                        : urlArr[0] + "//" + urlArr[2] + "/" + language + "/" + urlArr[3] + "/" + remainingURL;

                const urls = url.replace(/\/+$/, "");

                location.href = urls;
                //alert(urls)
                //location.href = "http://localhost:8080/employeePreview.html";
                //window.localStorage.setItem("selectedValue", newVal.label);
            }
        }
    },
    mounted() {
        let urlArr = window.location.toString().split("/");
        if (urlArr.indexOf("es-es") > 1) {
            var element = document.getElementById("enrollmentExpire");
            element.classList.add("econtentSpanish");
        }
        if (urlArr.indexOf("en") > -1) {
            document.getElementById("enrollmentExpire").classList.remove("econtentSpanish");
        }
        //let urlArr="https://dev-admin.aflacenrollment.com/es-es/Microsite?id=4e2acb9c-ab01-4b21-88f1-62adcc0ca81c".split("/")
        let languageCode = urlArr.indexOf("en") > 0 || urlArr.indexOf("es-es") > 0 ? urlArr[3] : null;
        let languageValuesId = document.getElementById("LanguageValues");
        this.languageValues = languageValuesId.value;
        //console.log("Select values",this.languageValues)
        if (this.languageValues) {
            let languageValuesArr = this.languageValues.split(",");
            if (Array.isArray(languageValuesArr)) {
                languageValuesArr.forEach(element => {
                    let optionArr = element.split("#");
                    this.options.push({ id: optionArr[0], label: optionArr[1] });
                    if (languageCode && languageCode == optionArr[0]) {
                        this.currentItem = optionArr[1];
                    }
                });
            }
        }
    },
    watch: {
        currentItem: {
            handler() {
                if (this.currentItem && typeof this.currentItem == "object") {
                    this.currentItem = this.currentItem;
                    this.currentLang = this.currentItem.label;
                    this.selectLang(this.currentItem);
                }
            },
            deep: true
        }
    }
};

if (document.getElementById(elementId)) {
    new Vue(vueElement);
}
