import Vue from "vue";
import { Vuelidate } from "vuelidate";
import httpClient from "../services/http-client";
import { required, email, minLength, maxLength } from "vuelidate/lib/validators";
import { helpers } from "vuelidate/lib/validators";
import flatPickr from "flatpickr";
import moment from "vue-moment";
import { getCookie } from "../common/util";
Vue.use(moment);
Vue.moment.suppressDeprecationWarnings = true;
Vue.use(Vuelidate);

const elementId = "suggestiveSelling";
export const vueElement = {
    el: `#${elementId}`,
    data: () => {
        return {
            showContactSection: false,
            hasSSTproduct: false,
            micrositeid: "",
            videoRef: null,
            productType: "",
            videoUrl: "",
            videoId: null,
            videoFlag: false,
            userData: {
                zipcode: "",
                gender: "",
                birthDate: "",
                companyIndustry: "",
                familyGroup: ""
            },
            coverageType: {},
            maleIndicator: {},
            submitted: false,
            companyList: [],
            industryList: [],
            birthDatePicker: null,
            zipErrormsg: false,
            productsList: [],
            heighLightProductList: [],
            isLoaderActive: false,
            isShowHightlightSection: false,
            isCoverageType: false,
            invalidZipcodes: []
        };
    },
    validations: {
        userData: {
            zipcode: { required },
            birthDate: { required },
            companyIndustry: { required },
            gender: { required }
        }
    },
    watch: {
        zipErrormsg: function() {
            if (/^(\d{5})?$/.test(this.userData.zipcode)) {
                return false;
            } else {
                return true;
            }
        }
    },
    methods: {
        showDetails(id) {
            var els = document.getElementsByClassName("hideContainer");
            var i;
            for (i = 0; i < els.length; i += 1) {
                els[i].style.display = "none";
            }
            document.getElementById(id).style.display = "block";
        },
        showModal(modal_id) {
            if (this.Dialog_flag == true) {
                $(modal_id).modal("show");
                this.Dialog_flag = false;
            }
        },
        hideDetails(id) {
            document.getElementById(id).style.display = "none";
        },

        onVideoClicked(event, videoId, AccountId) {
            document.getElementById("popup").classList.add("in");
            let videoURL = `https://players.brightcove.net/${AccountId}/default_default/index.html?videoId=${videoId}`;
            this.videoFlag = false;
            this.videoUrl = videoURL;
            this.videoFlag = true;
        },

        onVideoClosed() {
            if (document.getElementById("popup")) {
                document.getElementById("popup").classList.remove("in");
                this.videoFlag = false;
            }
        },
        async onMounted() {
            this.micrositeid = await getCookie("micrositeid");
            this.createCalendar();
            this.getFormValue();
        },
        getFormValue: function() {
            return new Promise(resolve => {
                httpClient.get("/SuggestiveSelling/FormData").then(result => {
                    this.companyList = result.data.Result.DivisionDescription;
                    this.coverageType = result.data.Result.CoverageType;
                    let maleIndicatorObj = {};
                    Object.keys(result.data.Result.MaleIndicator).forEach(function(key, index = 1) {
                        maleIndicatorObj[key] = result.data.Result.MaleIndicator[key] + "#" + index;
                    });
                    this.maleIndicator = maleIndicatorObj;
                    // new data for invalid zipcode
                    let InvalidZipCode = result.data.Result.InvalidZipCode;
                    if (InvalidZipCode) {
                        this.invalidZipcodes = InvalidZipCode.split(",").map(String);
                    }
                    //this.maleIndicator = result.data.Result.MaleIndicator;
                    // this.userData.familyGroup = this.coverageType.MySelf;
                    resolve();
                });
            });
        },

        getProduct: function(data) {
            this.productsList = [];
            this.heighLightProductList = [];
            setTimeout(function() {
                var elmntToView = document.getElementById("hightlightSection");
                elmntToView.scrollIntoView();
            }, 0);
            return new Promise(resolve => {
                if (this.userData.zipcode) {
                    httpClient.post(`/Products/Predict`, data).then(result => {
                        // httpClient.get(`/Products/Predicts`).then(result => {
                        if (result.data.Result) {
                            this.productsList = result.data.Result;
                            this.hasSSTproduct = result.data.hasSSTproduct == true ? true : false;
                        }
                        let results = JSON.parse(JSON.stringify(this.productsList));
                        this.productsList = results.splice(2);
                        this.heighLightProductList = results;
                        this.isLoaderActive = false;
                        this.showContactSection = true;
                        resolve();
                    });
                }
            });
        },
        randomRequestID(length = 8) {
            // Declare all characters
            let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
            // Pick characers randomly
            let str = "";
            for (let i = 0; i < length; i++) {
                str += chars.charAt(Math.floor(Math.random() * chars.length));
            }

            const today = new Date();
            const yyPadTo = ("0000" + today.getFullYear()).slice(-4);
            const monthPadTo = ("0" + (today.getMonth() + 1)).slice(-2);
            const datePadTo = ("0" + today.getDate()).slice(-2);
            const hhPadTo = ("0" + today.getHours()).slice(-2);
            const mmPadTo = ("0" + today.getMinutes()).slice(-2);
            const ssPadTo = ("0" + today.getSeconds()).slice(-2);
            return str + "-" + yyPadTo + "" + monthPadTo + "" + datePadTo + "" + hhPadTo + "" + mmPadTo + "" + ssPadTo;
        },
        viewProduct() {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid || this.zipErrormsg) {
                return;
            }

            var data = {
                RequestId: this.randomRequestID(),
                Dob: Vue.moment(this.userData.birthDate).format("YYYY-MM-DD"),
                MaleInd: this.userData.gender.split("#")[0],
                CoverageType: this.userData.familyGroup,

                Zip: this.userData.zipcode,
                DivisionDescription: this.userData.companyIndustry.CompanyName,
                MicrositeId: this.micrositeid

                /* These filed provided by backend microsite
                 "City": this.userData.city,
                 "State": this.userData.state.StateId,
                 "FirstName": "Will",
                 "LastName": "Smith",
                 "Address": "house #, street name/number",
                 "DiagnosedWith": "Concussions",
                 "PayrollInd": "1"
                 */
            };

            // this.goToProductDiv();
            this.isShowHightlightSection = true;
            this.isLoaderActive = true;
            this.getProduct(data);
        },
        scrollOnCoverageType() {
            this.isCoverageType = true;
            setTimeout(function() {
                var elmntToView = document.getElementById("coverage-type");

                elmntToView.scrollIntoView({
                    behavior: "smooth"
                });
            }, 100);
        },
        isNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
        },
        isNotNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (!(keyCode < 48 || keyCode > 57)) {
                $event.preventDefault();
            }
        },
        validateZip($event, value) {
            // if (/^(\d{5})?$/.test(value)) {
            //     this.zipErrormsg = false;
            // } else {
            //     this.zipErrormsg = true;
            // }

            if (this.invalidZipcodes.includes(value)) {
                this.zipErrormsg = true;
                $event.preventDefault();
            } else {
                this.zipErrormsg = false;
            }
        },
        createCalendar() {
            const self = this;
            this.birthDatePicker = flatPickr("#datetimepicker", {
                allowInput: false,
                altInput: true,
                altFormat: "m/d/Y",
                // minDate: "today",
                maxDate: new Date(),
                dateFormat: "m/d/Y",
                defaultDate: []
            });
        }
    },

    mounted() {
        let urlArr = window.location.toString().split("/");
        if (urlArr.indexOf("es-es") > 1) {
            var element = document.getElementById("suggestiveSelling");
            element.classList.add("contentSpanishSST");
        }

        this.onMounted();
        var footer = document.getElementsByClassName("preview-Footer");
        footer[0].classList.add("suggestive-Footer");
    }
};

if (document.getElementById(elementId)) {
    new Vue(vueElement);
}
