import Vue from "vue";
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

const elementId = "accessDenied";
const vueElement = {
    el: `#${elementId}`,
    data: {},
    methods: {}
};

if (document.getElementById(elementId)) {
    new Vue(vueElement).$mount();
}
