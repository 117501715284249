import httpClient from "../services/http-client";
import environment from "../services/environment";

/* eslint-disable */
export const getQueryStringValue = function(key) {
    return decodeURIComponent(
        window.location.search.replace(
            new RegExp(
                "^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$",
                "i"
            ),
            "$1"
        )
    );
};
export const getUser = function() {
    return new Promise(resolve => {
        if (environment.name === "local") {
            document.cookie = "username=sam@gmail.com;";
        }
        const userEmail = getCookie("username");
        const loggedInUserDetail = JSON.parse(localStorage.getItem("loggedInUserDetail"));
        if (userEmail) {
            httpClient.get(`Account/Get?username=${userEmail}`).then(result => {
                let resData = environment.name === "local" ? result.data[0] : result.data;
                if (resData) {
                    localStorage.setItem("loggedInUserDetail", JSON.stringify(resData));
                    resolve(resData);
                } else {
                    resolve();
                }
            });
        } else {
            resolve();
        }
        // if (loggedInUserDetail && loggedInUserDetail.EmailAddress == userEmail) {
        //     resolve(loggedInUserDetail);
        // } else {
        //     if (userEmail) {
        //         httpClient.get(`Account/Get?username=${userEmail}`).then(result => {
        //             let resData = environment.name === "local" ? result.data[0] : result.data;
        //             if (resData) {
        //                 localStorage.setItem("loggedInUserDetail", JSON.stringify(resData));
        //                 resolve(resData);
        //             } else {
        //                 resolve();
        //             }
        //         });
        //     } else {
        //         resolve();
        //     }
        // }
    });
};
export const getCookie = function(name) {
    // Split cookie string and get all individual name=value pairs in an array
    var cookieArr = document.cookie.split(";");

    // Loop through the array elements
    for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");

        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if (name == cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }

    // Return null if not found
    return null;
};
window.goTopreviousPage = function() {
    let url = getCookie("previewReturnURL");
    let decodedString = decodeURIComponent(url);
    if (decodedString == "/Account") {
        window.location.href = "/Account";
    } else {
        let micrositeId = decodedString.split("=")[1];
        window.location.href = "/Create-New-Site?micrositeid=" + micrositeId;
    }
};

window.triggerNavigation = function(link, event) {
    event.preventDefault();
    var elem = document.getElementById("dirtyFlag");
    if (elem) {
        if (elem.value === "dirty") {
            elem.value = "showMessage";
            var event = new Event("change", {
                bubbles: true
            });
            elem.dispatchEvent(event);
            document.getElementById("dirtyFlag").setAttribute("data-buttonVal", link);
            return false;
        }

        if (elem.value === "navigate" || elem.value === "pristine") {
            window.location.href = link.href;
        }
    } else {
        window.location.href = link.href;
    }
};
