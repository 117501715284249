import Vue from "vue";
import Vuelidate from "vuelidate";
import stateJson from "./../assets/stubs/preview_state.json";
Vue.use(Vuelidate);

const elementId = "preview";
const vueElement = {
    el: `#${elementId}`,
    data: {
        // message: "this is Vue test Profile",
        // user: {
        //     firstName: "",
        //     email: ""
        // },
        // submitted: false
        selectedState: "state1",
        states: stateJson,
        selectedStateList: []
    },
    methods: {
        getState(stateName) {
            for (let state in this.states) {
                if (state === stateName) {
                    return this.states[state];
                }
            }
            return null;
        },
        onStateChange() {
            this.selectedStateList = this.getState(this.selectedState);
        }
    },
    mounted() {
        this.selectedStateList = this.getState(this.selectedState);
    }
};

if (document.getElementById(elementId)) {
    new Vue(vueElement).$mount();
}
